import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import overviewApi from "../api/OverviewApi";
import Button from "../components/common/Button";
import VideoPlayer from "../components/video/VideoPlayer";
import VideoRecorder from "../components/video/videoRecorder";
import { PAGE_INFOQUESTIONSTEST } from "../consts/Pages";
import { VideoRecorderQuestion } from "../models/VideoRecorderQuestion";
import { logAnalyzer } from "../utils/api";
import browser from "../utils/browser";

enum Status {
  Recording,
  Replay,
  Buttons,
}

const CameraTestPage: FC = () => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();
  const [status, setStatus] = useState<Status>(Status.Recording);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [volumeAnalyzerState, setVolumeAnalyzerState] = useState<string>("error");

  const handleTestQuestion = async (): Promise<VideoRecorderQuestion> => {
    return {
      hasNextQuestion: false,
      duration: 10000,
      text1: t("cameraTest.recordTitle"),
      text2: t("cameraTest.recordText"),
    };
  };

  const handleRecordEnd = async (
    videoData: Blob,
    thumbnail: Blob
  ): Promise<boolean> => {
    setVideoUrl(window.URL.createObjectURL(videoData));
    setStatus(Status.Replay);
    return true;
  };

  const handleVolumeAnalyzerState = (state: string) => {
    logAnalyzer(data.id, data.logId, state);
    setVolumeAnalyzerState(state);
  }

  const handleReplayEnded = () => {
    window.URL.revokeObjectURL(videoUrl);
    setVideoUrl("");

    //iPhone bug
    if (browser.name === "ios") {
      setTimeout(() => setStatus(Status.Buttons), 500);
    } else {
      setStatus(Status.Buttons);
    }
  };

  switch (status) {
    case Status.Recording:
      return (
        <VideoRecorder
          cameraTestMode={true}
          questionsTestMode={false}
          onReadQuestion={handleTestQuestion}
          onRecordEnd={handleRecordEnd}
          onVolumeAnalyzerState={handleVolumeAnalyzerState}
        />
      );
    case Status.Replay:
      return (
        <VideoPlayer
          src={videoUrl}
          testMode={true}
          onEnded={handleReplayEnded}
        />
      );
    default:
      return (
        <div className="card">
          <div className="page-content">
            {
              volumeAnalyzerState !== "ok" && (
                volumeAnalyzerState === "notReached"
                  ? <p>{t("cameraTest.notReached")}</p>
                  : <p>{t("cameraTest.analyzerError")}</p>
              )
            }
            <p>{t("cameraTest.previewEnd")}</p>
            <div className="video-preview-actions">
              <Button
                lg
                onClick={() => setStatus(Status.Recording)}
                captionRes="common.again"
              />
              <Button lg to={PAGE_INFOQUESTIONSTEST} captionRes="common.continue" />
            </div>
          </div>
        </div>
      );
  }
};

export default CameraTestPage;
