import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/Button";

const CameraTestActions: FC<{
  runDisabled: boolean;
  onClick?(): void;
}> = ({ runDisabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="page-actions">
      <div className="question">
        <p>{t("video.startRecordTitle")}</p>
      </div>
      <div className="actions">
        <Button
          lg
          onClick={onClick}
          disabled={runDisabled}
          captionRes="common.run"
        />
      </div>
    </div>
  );
};

export default CameraTestActions;
