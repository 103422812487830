import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import overviewApi from "../api/OverviewApi";
import Button from "../components/common/Button";
import { PAGE_CAMERATEST, PAGE_INSTRUCTION, PAGE_TERMS } from "../consts/Pages";
import { Link } from "react-router-dom";

const OverviewPage: FC = () => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();
  const [checked, setChecked] = useState<boolean>(false);

  const questionsLengthMs = data.questions
    .map((x) => x.duration)
    .reduce((a, b) => a + b, 0);
  const questionsLengthMin = Math.ceil(questionsLengthMs / 60000);

  return (
    <>
      <div className="card">
        <div className="page-content">
          <div className="overview-info">
            <div className="overview-info__row">
              <div>{t("overview.name")}</div>
              <div className="primary">{data.name}</div>
            </div>
            <div className="overview-info__row">
              <div>{t("overview.position")}</div>
              <div>{data.position}</div>
            </div>
            <div className="overview-info__row">
              <div>{t("overview.activeDue")}</div>
              <div>{new Date(data.activeDue).toLocaleString()}</div>
            </div>
          </div>
          <div className="divider" />
          <p>{t("overview.bottomText")}</p>
          <p>
            {t("overview.allLength", {
              questionsCount: data.questions.length,
              questionsLength: questionsLengthMin,
            })}
          </p>
          <p className="overview-checkbox">
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />{" "}
              {t("overview.terms1")}{" "}
              <Link to={PAGE_TERMS}>{t("overview.terms2")}</Link>
            </label>
          </p>
        </div>
      </div>
      <div className="page-actions__center">
        <Button
          to={!checked ? "#" : data.video ? PAGE_INSTRUCTION : PAGE_CAMERATEST}
          disabled={!checked}
          captionRes="common.continue"
        />
      </div>
    </>
  );
};

export default OverviewPage;
