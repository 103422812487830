import React, { FC } from "react";
import Button from "../../common/Button";

const Question: FC<{
  isRecording: boolean;
  cameraTestMode: boolean;
  questionsTestMode: boolean;
  isNextQuestionDisabled: boolean;
  text1: string;
  text2: string;
  hasNextQuestion: boolean;
  onNextQuestionClick(): void;
  onSkipTestClick(): void;
}> = ({
  isRecording,
  cameraTestMode,
  questionsTestMode,
  isNextQuestionDisabled,
  text1,
  text2,
  hasNextQuestion,
  onNextQuestionClick,
  onSkipTestClick,
}) => {
  return (
    <div className="page-actions">
      <div className="question">
        <p>
          <b>{text1}</b>
          <br />
          {text2}
        </p>
      </div>
      <div className="actions">
        <Button
          lg
          onClick={() => onNextQuestionClick()}
          disabled={cameraTestMode || !isRecording || (questionsTestMode && !hasNextQuestion) || isNextQuestionDisabled}
          captionRes="video.nextQuestion"
        />
        {questionsTestMode && (
          <Button
            lg
            onClick={() => onSkipTestClick()}
            disabled={cameraTestMode || !isRecording}
            captionRes="video.endTest"
          />
        )}
      </div>
    </div>
  );
};

export default Question;
