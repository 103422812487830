import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SpinBox: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="spinner-container">
      <FontAwesomeIcon
        className="spinner-img"
        icon={faSpinner}
        size="2x"
        spin={true}
      />
      <p className="spinner-text">{t("common.loading")}</p>
    </div>
  );
};

export default SpinBox;
