import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../components/common/Button";
import { PAGE_QUESTIONS, PAGE_QUESTIONSTEST } from "../consts/Pages";

const InfoQuestionsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="page-content info-page">
        <p>{t("infoQuestions.line1")}</p>
        <p><b>{t("infoQuestions.line2a")}</b> {t("infoQuestions.line2b")}</p>
        <p>{t("infoQuestions.line3")}</p>
        <p>
          <b>{t("infoQuestions.line4")}{" "}</b>
          <Link className="info-page__actions-link" to={PAGE_QUESTIONSTEST}>
            <b>{t("infoQuestions.testAgain")}</b>
          </Link>
        </p>
        <div className="info-page__actions">
          <Button lg to={PAGE_QUESTIONS} captionRes="infoQuestions.runRecord" />
        </div>
      </div>
    </div>
  );
};

export default InfoQuestionsPage;
