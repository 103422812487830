export const PAGE_WELCOME = "/welcome";
export const PAGE_PATHERROR = "/patherror";
export const PAGE_SERVERERROR = "/servererror";
export const PAGE_OVERVIEWNOTFOUND = "/overviewnotfound";
export const PAGE_ALREADYANSWERED = "/alreadyanswered";
export const PAGE_DECLINECONFIRM = "/declineconfirm"
export const PAGE_DECLINED = "/declined"
export const PAGE_OVERVIEW = "/overview";
export const PAGE_CAMERATEST = "/cameratest";
export const PAGE_INSTRUCTION = "/instruction";
export const PAGE_TERMS = "/terms";
export const PAGE_INFOQUESTIONSTEST = "/infoquestionstest";
export const PAGE_QUESTIONSTEST = "/questionstest";
export const PAGE_INFOQUESTIONS = "/infoquestions";
export const PAGE_QUESTIONS = "/questions";
export const PAGE_UPLOADING = "/uploading";
export const PAGE_UPLOADED = "/uploaded";
export const PAGE_FINISH_ERROR = "/finisherror";
export const PAGE_FINISH_SUCCESS = "/finishsuccess";
