import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../components/common/Button";
import { PAGE_INFOQUESTIONS, PAGE_QUESTIONSTEST } from "../consts/Pages";

const InfoQuestionsTestPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="page-content info-page">
        <p>{t("infoQuestionsTest.line1")}</p>
        <p>{t("infoQuestionsTest.line2")}</p>
        <p>{t("infoQuestionsTest.line3")}</p>
        <p>{t("infoQuestionsTest.line4")}</p>
        <div className="info-page__actions">
          <Button
            lg
            to={PAGE_QUESTIONSTEST}
            captionRes="infoQuestionsTest.runTest"
          />
          <Link className="info-page__actions-link" to={PAGE_INFOQUESTIONS}>
            {t("infoQuestionsTest.skipTest")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InfoQuestionsTestPage;
