import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoRecorder from "../components/video/videoRecorder";
import { PAGE_INFOQUESTIONS } from "../consts/Pages";
import { VideoRecorderQuestion } from "../models/VideoRecorderQuestion";

const QuestionsTestPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const questionsCount = 3;
  var questionIndex = 1;

  const hasNewQuestion = (): boolean => {
    return questionIndex < questionsCount;
  };

  const handleTestQuestion = async (): Promise<VideoRecorderQuestion> => {
    return {
      hasNextQuestion: hasNewQuestion(),
      duration: 60000,
      text1: t("testQuestions.title", { index: questionIndex }),
      text2: t("testQuestions.question" + questionIndex),
    };
  };

  const handleRecordEnd = async (
    videoData: Blob,
    thumbnail: Blob
  ): Promise<boolean> => {
    if (!hasNewQuestion()) {
      handleSkipTest();
    }

    questionIndex++;
    return true;
  };

  const handleSkipTest = () => {
    navigate(PAGE_INFOQUESTIONS);
  };

  return (
    <VideoRecorder
      cameraTestMode={false}
      questionsTestMode={true}
      onReadQuestion={handleTestQuestion}
      onRecordEnd={handleRecordEnd}
      onSkipTest={handleSkipTest}
    />
  );
};

export default QuestionsTestPage;
