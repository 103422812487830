import React, { FC, useState } from "react";
import i18next from 'i18next';
import { Translation } from "react-i18next";
import { LANGUAGES, LANGUAGES_IMAGES } from "../../consts/Languages";
import ClickOutside from "./ClickOutside";

interface Lang {
  code: string;
  img: string;
}

function getLanguages() {
  var res = new Array<Lang>();
  for (var i = 0; i < LANGUAGES.length; i++) {
    res.push({
      code: LANGUAGES[i],
      img: LANGUAGES_IMAGES[i],
    });
  }

  return res;
}

const LanguageSwitcher: FC = () => {
  const languages = getLanguages();
  const [open, setOpen] = useState<boolean>(false);

  const change = (code: string) => {
    setOpen(false);
    i18next.changeLanguage(code);
  }

  const toggle = (newState: boolean) => {
    setOpen(newState);
  }

  return (
    <div className="lang-container">
      {
        open &&
          <ClickOutside onClickOutside={() => toggle(false)}>
            <div className="lang-switcher">
              {
                languages.map(lang => (
                  <img src={lang.img} alt={lang.code} key={lang.code} onClick={() => change(lang.code)} />
                ))
              }
            </div>
          </ClickOutside>
      }
      <div className="lang-selected">
        <Translation>
          {
            (_t, options) =>
              languages.map(lang => (
                lang.code === options.lng &&
                  <img src={lang.img} alt={lang.code} key="selected" onClick={() => toggle(true)} />
              ))
          }
        </Translation>
      </div>
    </div>
  );
}

export default LanguageSwitcher;