var showMessageWhenLeave: boolean = false;

export const setShowMessageWhenLeave = (value: boolean) => {
  showMessageWhenLeave = value;
}

window.addEventListener('beforeunload', function (e) {
  if (showMessageWhenLeave) {
    e.preventDefault();
    e.returnValue = '';
  } else {
    delete e['returnValue'];
  }
});