import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const ServerErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page-content">
      <h1>{t("serverError.title")}</h1>
      <p>{t("serverError.text")}</p>
    </div>
  );
};

export default ServerErrorPage;
