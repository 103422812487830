import React, { Component } from "react";

interface IProps {
  endTime: number;
}

interface IState {
  value: number;
}

export default class CountDown extends Component<IProps, IState> {
  private timeout: NodeJS.Timeout | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    this.updateNumber();
  }

  componentWillUnmount() {
    this.timeout && clearInterval(this.timeout);
  }

  updateNumber = () => {
    this.timeout = null;
    const now = new Date().getTime();
    const msDiff = this.props.endTime - now;
    const sDiff = Math.ceil(msDiff / 1000);
    const value = Math.max(sDiff, 0);

    this.setState({
      value: value,
    });

    if (value === 0) {
      return;
    }

    this.timeout = setTimeout(this.updateNumber, 500);
  };

  render() {
    const { value } = this.state;

    if (value === 0) {
      return null;
    }

    return (
      <div className="video-countdown">
        <p>{value}</p>
      </div>
    );
  }
}
