import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Button: FC<{
  to?: string;
  onClick?(): void;
  captionRes: string;
  disabled?: boolean;
  lg?: boolean;
}> = ({ to, onClick, captionRes, disabled, lg }) => {
  const { t } = useTranslation();

  if (disabled) {
    return (
      <button className={`button disabled ${lg ? "lg" : ""}`}>
        {t(captionRes)}
      </button>
    );
  }

  if (to) {
    return (
      <Link to={to} className={`button ${lg ? "lg" : ""}`}>
        {t(captionRes)}
      </Link>
    );
  }

  return (
    <button
      onClick={() => onClick && onClick()}
      className={`button ${lg ? "lg" : ""}`}
    >
      {t(captionRes)}
    </button>
  );
};

export default Button;
