import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import overviewApi from "../api/OverviewApi";
import SpinBox from "../components/common/SpinBox";
import {
  PAGE_ALREADYANSWERED,
  PAGE_DECLINECONFIRM,
  PAGE_OVERVIEWNOTFOUND,
  PAGE_PATHERROR,
  PAGE_SERVERERROR,
  PAGE_WELCOME,
} from "../consts/Pages";

const FirstPage: FC = () => {
  const navigate = useNavigate();

  const load = async () => {
    const path = window.location.pathname.split("/");
    if (path.length < 3) {
      navigate(PAGE_PATHERROR);
      return;
    }

    const id = path[2];
    if (!id) {
      navigate(PAGE_PATHERROR);
      return;
    }

    const decline = (path.length > 3) && (path[3].toLocaleLowerCase() === "decline");

    const loadRes = await overviewApi.loadOverview(id);
    switch (loadRes) {
      case overviewApi.LoadResponse.Ok:
        if (decline) {
          navigate(PAGE_DECLINECONFIRM);
          return;
        }

        navigate(PAGE_WELCOME);
        return;
      case overviewApi.LoadResponse.NotFound:
        navigate(PAGE_OVERVIEWNOTFOUND);
        return;
      case overviewApi.LoadResponse.AlreadyAnswered:
        navigate(PAGE_ALREADYANSWERED);
        return;
    }

    navigate(PAGE_SERVERERROR);
  };

  useEffect(() => {
    (async () => {
      await load();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SpinBox />;
};

export default FirstPage;
