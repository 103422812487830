import React, { Component } from "react";

interface IProps {
  endTime: number;
}

interface IState {
  value: string;
}

export default class Recording extends Component<IProps, IState> {
  private timeout: NodeJS.Timeout | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    this.updateNumber();
  }

  componentWillUnmount() {
    this.timeout && clearInterval(this.timeout);
  }

  updateNumber = () => {
    this.timeout = null;
    const now = new Date().getTime();
    const msDiff = this.props.endTime - now;
    const sDiff = Math.ceil(msDiff / 1000);
    const value = Math.max(sDiff, 0);

    const partMin = Math.floor(value / 60).toString();
    const partSec = (value % 60).toString();
    const partSecStr = partSec.length === 1 ? "0" : "";
    const result = partMin + ":" + partSecStr + partSec;

    this.setState({
      value: result,
    });

    if (value === 0) {
      return;
    }

    this.timeout = setTimeout(this.updateNumber, 500);
  };

  render() {
    const { value } = this.state;

    return (
      <div className="video-recording-timer">
        <span className="video-recording-timer__icon" />
        <p>{value}</p>
      </div>
    );
  }
}
