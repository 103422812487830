import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";
import { DEFAULT_LANG, LANGUAGES } from "../consts/Languages";
import tranEn from "../languages/en.json";
import tranCs from "../languages/cs.json";
import tranPl from "../languages/pl.json";
import tranEs from "../languages/es.json";

const resources = {
  en: {
    translation: tranEn,
  },
  cs: {
    translation: tranCs,
  },
  pl: {
    translation: tranPl,
  },
  es: {
    translation: tranEs,
  },
};

function getLang() {
  const path = window.location.pathname.split("/");
  if (path.length < 2) {
    return DEFAULT_LANG;
  }

  const lang = path[1];
  if (!lang) {
    return DEFAULT_LANG;
  }

  if (!LANGUAGES.includes(lang)) {
    return DEFAULT_LANG;
  }

  return lang;
}

i18n
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources,
    lng: getLang(),
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
