import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import overviewApi from "../api/OverviewApi";
import SpinBox from "../components/common/SpinBox";
import { PAGE_SERVERERROR } from "../consts/Pages";

enum Status {
  Working,
  ServerError,
  Ok,
}

const DeclinedPage: FC = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.Working);
  const navigate = useNavigate();

  const work = async (): Promise<Status> => {
    const data = overviewApi.getOverview();
    const declineRes = await overviewApi.decline(data.id, data.logId);

    const res = declineRes ? Status.Ok : Status.ServerError;

    if (res === Status.ServerError) {
      navigate(PAGE_SERVERERROR);
    }

    return res;
  }

  useEffect(() => {
    (async () => {
      const res = await work();
      setStatus(res);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status !== Status.Ok) {
    return <SpinBox />;
  }

  return (
    <div className="page-content">
      <h1>{t("declined.title")}</h1>
      <p>{t("declined.text")}</p>
    </div>
  );
};

export default DeclinedPage;
