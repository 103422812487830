import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/common/Button";
import { PAGE_DECLINED, PAGE_WELCOME } from "../consts/Pages";

const DeclineConfirmPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page-content">
      <h1>{t("declineConfirm.title")}</h1>
      <p>{t("declineConfirm.text")}</p>
      <p>{t("declineConfirm.warning")}</p>
      <Button lg to={PAGE_WELCOME} captionRes="declineConfirm.back" />
      <Button lg to={PAGE_DECLINED} captionRes="declineConfirm.confirm" />
    </div>
  );
};

export default DeclineConfirmPage;
