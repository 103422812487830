import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/Button";
import browser from "../../../utils/browser";
import { isSafariVersionOk } from "../../../utils/versions";
import { logError } from "../../../utils/api";
import overviewApi from "../../../api/OverviewApi";

const Error: FC<{
  errorText: string;
  mimeType: string;
  blurActivated: boolean;
  onClick(): void;
}> = ({ errorText, mimeType, blurActivated, onClick }) => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();

  useEffect(() => {
    (async () => {
      await logError(data.id, data.logId, `${mimeType} with${blurActivated ? "" : "out"} blur ${errorText}`);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let text = null;
  let commonError = true;
  let showRetry = false;

  if (!text) {
    if (errorText.startsWith("Get user media:")) {
      const umError = errorText.substring(16);

      if (
        umError === "Permission denied" ||
        umError === "Permission dismissed" ||
        umError === "Permission denied by system" ||
        umError === "Requested device not found" ||
        umError.startsWith("The request is not allowed by the user agent")
      ) {
        text = "noPermissions";
        showRetry = true;
      }

      if (
        umError === "Could not start video source" ||
        umError === "Could not start audio source" ||
        umError === "Starting videoinput failed" ||
        umError === "Failed to allocate videosource"
      ) {
        text = "inUse";
        showRetry = true;
      }
    }
  }

  if (!text) {
    switch(browser.name) {
      case "safari":
        if (!isSafariVersionOk(browser.version)) {
          text = "unsupportedUpdateSafari";
        }
        break;
      case "ios":
        if (!isSafariVersionOk(browser.version)) {
          text = "unsupportedUpdateIos";
        }
    }
  }

  if (!text) {
    if (browser.os === "iOS") {
      text = "errorIos";
    }
  }

  if (!text) {
    text = "errorTitle";
    commonError = false;
    showRetry = true;
  }

  return (
    <div className="video-error">
      <p>{t("video." + text)}</p>
      {!commonError && (
        <p>
          {browser.name} {browser.version} - {browser.os} - {mimeType} with{blurActivated ? "" : "out"} blur
          <br />
          {errorText}
        </p>
      )}
      {showRetry && (
        <Button onClick={onClick} captionRes="common.again" />
      )}
    </div>
  );
};

export default Error;
