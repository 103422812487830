import React, { FC } from "react";
import overviewApi from "../api/OverviewApi";
import Button from "../components/common/Button";
import VideoPlayer from "../components/video/VideoPlayer";
import { PAGE_CAMERATEST } from "../consts/Pages";

const InstructionPage: FC = () => {
  const data = overviewApi.getOverview();

  return (
    <>
      <VideoPlayer src={data.video} testMode={false} />
      <Button to={PAGE_CAMERATEST} captionRes="common.continue" />
    </>
  );
};

export default InstructionPage;
