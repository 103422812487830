import React, { FC } from "react";
import { useLocation } from "react-router";
import Logo from "../../images/logo.png";
import {
  PAGE_CAMERATEST,
  PAGE_FINISH_SUCCESS,
  PAGE_OVERVIEW,
  PAGE_QUESTIONS,
  PAGE_QUESTIONSTEST,
  PAGE_WELCOME,
} from "../../consts/Pages";
import { useTranslation } from "react-i18next";
import overviewApi from "../../api/OverviewApi";

const steps = [
  PAGE_WELCOME,
  PAGE_OVERVIEW,
  PAGE_CAMERATEST,
  PAGE_QUESTIONSTEST,
  PAGE_QUESTIONS,
  PAGE_FINISH_SUCCESS,
];

const Header: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dataLoaded = overviewApi.getOverviewLoaded();
  const data = overviewApi.getOverview();

  let logoSrc;
  if (dataLoaded) {
    logoSrc = data?.logo ? data.logo : Logo;
  }

  return (
    <header>
      <img src={logoSrc} alt="Logo" style={{ visibility: dataLoaded ? undefined : "hidden" }} />
      {steps.map((step, i) => (
        <div
          key={step}
          className={`step ${pathname === step ? "active-step" : ""}`}
        >
          {i + 1}. {t(`header.${step}`)}
        </div>
      ))}
    </header>
  );
};

export default Header;
