import React, { FC, useRef, useState, useEffect } from "react";
import { Translation } from "react-i18next";
import dataSendApi from "../api/DataSendApi";
import overviewApi from "../api/OverviewApi";
import Progress from "../components/common/Progress";
import SpinBox from "../components/common/SpinBox";
import { useNavigate } from "react-router-dom";
import { PAGE_UPLOADED } from "../consts/Pages";

const UploadingPage: FC = () => {
  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [percentArray, setPercentArray] = useState<Array<number>>([]);
  const data = overviewApi.getOverview();

  useEffect(() => {
    check();

    return () => {
      if (timeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const check = () => {
    dataSendApi.runStuckUpload();
    const allIsUploaded = dataSendApi.getAllIsUploaded();
    if (allIsUploaded) {
      navigate(PAGE_UPLOADED);
      return;
    }

    const newPercentArray = new Array<number>(data.questions.length);
    for (var i = 0; i < data.questions.length; i++) {
      newPercentArray[i] = dataSendApi.getPercent(data.questions[i].id);
    }

    setPercentArray(newPercentArray);
    timeout.current = setTimeout(check, 1000);
  }

  return (
    <div className="page-content">
      <SpinBox />
      <Translation>
        {(t) =>
          percentArray.map((percent, index) => (
            <Progress
              key={index}
              caption={t("questions.title", { index: index + 1 })}
              percent={percent}
            />
          ))
        }
      </Translation>
    </div>
  );
}

export default UploadingPage;