import React, { FC } from "react";
import CameraTestActions from "./components/CameraTestActions";
import browser from "../../utils/browser";

const isSafariOrIos = browser.name === "safari" || browser.name === "ios" || browser.os === "iOS";

const VideoPlayer: FC<{
  src: string;
  testMode: boolean;
  onEnded?(): void;
}> = ({ src, testMode, onEnded }) => {
  return (
    <>
      <div className="video-container" key="videoPlayerContainer">
        <video
          key="videoPlayerVideo"
          src={src}
          loop={false}
          muted={false}
          playsInline={true}
          autoPlay={true}
          controls={!testMode || isSafariOrIos}
          onEnded={() => onEnded && onEnded()}
        />
      </div>
      {testMode && <CameraTestActions runDisabled={true} />}
    </>
  );
};

export default VideoPlayer;
