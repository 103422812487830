import { Overview, OverViewStatus } from "../models/Overview";
import { callApiGet, logError } from "../utils/api";
import browser from "../utils/browser";

enum LoadResponse {
  Error,
  NotFound,
  AlreadyAnswered,
  Ok,
}

var storedDataLoaded: boolean = false;
var storedData: Overview;

async function loadOverview(id: string): Promise<LoadResponse> {
  const result = await loadOverview2(id);
  storedDataLoaded = true;
  return result;
}

async function loadOverview2(id: string): Promise<LoadResponse> {
  const response = await callApiGet(`/${id}?browserName=${browser.name}&browserVersion=${browser.version}&osName=${browser.os}`);
  if (!response) {
    return LoadResponse.Error;
  }

  if (response.status === 404) {
    return LoadResponse.NotFound;
  }

  if (!response.ok) {
    return LoadResponse.Error;
  }

  var data = await response.json();
  data.id = id;

  if (data.status !== OverViewStatus.Pending) {
    return LoadResponse.AlreadyAnswered;
  }

  setOverview(data);
  return LoadResponse.Ok;
}

function getOverviewLoaded() {
  return storedDataLoaded;
}

function setOverview(data: Overview) {
  storedData = data;
}

function getOverview(): Overview {
  return storedData;
}

async function decline(id: string, logId: number): Promise<boolean> {
  const response = await callApiGet(`/${id}/decline?logId=${logId}`);
  const result = response !== null && response.ok;

  if (!result) {
    await logError(id, logId, "Decline");
  }

  return result;
}

const overviewApi = {
  LoadResponse,
  loadOverview,
  getOverviewLoaded,
  setOverview,
  getOverview,
  decline,
};

export default overviewApi;
