import React, { FC } from "react";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import {
  PAGE_ALREADYANSWERED,
  PAGE_CAMERATEST,
  PAGE_DECLINECONFIRM,
  PAGE_DECLINED,
  PAGE_FINISH_ERROR,
  PAGE_FINISH_SUCCESS,
  PAGE_INFOQUESTIONS,
  PAGE_INFOQUESTIONSTEST,
  PAGE_INSTRUCTION,
  PAGE_OVERVIEW,
  PAGE_OVERVIEWNOTFOUND,
  PAGE_PATHERROR,
  PAGE_QUESTIONS,
  PAGE_QUESTIONSTEST,
  PAGE_SERVERERROR,
  PAGE_TERMS,
  PAGE_UPLOADED,
  PAGE_UPLOADING,
  PAGE_WELCOME,
} from "./consts/Pages";
import AlreadyAnsweredPage from "./pages/AlreadyAnsweredPage";
import FirstPage from "./pages/FirstPage";
import InstructionPage from "./pages/InstructionPage";
import OverviewNotFoundPage from "./pages/OverviewNotFoundPage";
import OverviewPage from "./pages/OverviewPage";
import PathErrorPage from "./pages/PathErrorPage";
import ServerErrorPage from "./pages/ServerErrorPage";
import TermsPage from "./pages/TermsPage";
import CameraTestPage from "./pages/CameraTestPage";
import WelcomePage from "./pages/WelcomePage";
import QuestionsTestPage from "./pages/QuestionsTestPage";
import QuestionsPage from "./pages/QuestionsPage";
import UploadingPage from "./pages/UploadingPage";
import UploadedPage from "./pages/UploadedPage";
import FinishErrorPage from "./pages/FinishErrorPage";
import FinishSuccessPage from "./pages/FinishSuccessPage";
import Layout from "./components/layout/Layout";
import ScrollToTop from "./components/common/ScrollToTop";
import InfoQuestionsTestPage from "./pages/InfoQuestionsTestPage";
import InfoQuestionsPage from "./pages/InfoQuestionsPage";
import DeclineConfirmPage from "./pages/DeclineConfirmPage";
import DeclinedPage from "./pages/DeclinedPage";

export const App: FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path={PAGE_PATHERROR} element={<PathErrorPage />} />
          <Route path={PAGE_SERVERERROR} element={<ServerErrorPage />} />
          <Route path={PAGE_OVERVIEWNOTFOUND} element={<OverviewNotFoundPage />} />
          <Route path={PAGE_ALREADYANSWERED} element={<AlreadyAnsweredPage />} />
          <Route path={PAGE_DECLINECONFIRM} element={<DeclineConfirmPage />} />
          <Route path={PAGE_DECLINED} element={<DeclinedPage />} />
          <Route path={PAGE_WELCOME} element={<WelcomePage />} />
          <Route path={PAGE_OVERVIEW} element={<OverviewPage />} />
          <Route path={PAGE_TERMS} element={<TermsPage />} />
          <Route path={PAGE_INSTRUCTION} element={<InstructionPage />} />
          <Route path={PAGE_CAMERATEST} element={<CameraTestPage />} />
          <Route path={PAGE_INFOQUESTIONSTEST} element={<InfoQuestionsTestPage />} />
          <Route path={PAGE_QUESTIONSTEST} element={<QuestionsTestPage />} />
          <Route path={PAGE_INFOQUESTIONS} element={<InfoQuestionsPage />} />
          <Route path={PAGE_QUESTIONS} element={<QuestionsPage />} />
          <Route path={PAGE_UPLOADING} element={<UploadingPage />} />
          <Route path={PAGE_UPLOADED} element={<UploadedPage />} />
          <Route path={PAGE_FINISH_ERROR} element={<FinishErrorPage />} />
          <Route path={PAGE_FINISH_SUCCESS} element={<FinishSuccessPage />} />
          <Route path="*" element={<FirstPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};
