import { Question } from "./Question";

export interface Overview {
  id: string;
  status: OverViewStatus;
  companyName: string;
  name: string;
  position: string;
  activeDue: Date;
  style: string;
  logo: string;
  video: string;
  logId: number;

  questions: Question[];
}

export enum OverViewStatus {
  Pending = "Pending",
  Declined = "Declined",
  Expired = "Expired",
  Recorded = "Recorded",
}
