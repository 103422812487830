export interface Question {
  showIndex: number;
  id: string;
  status: QuestionStatus;
  duration: number;
  text: string;
}

export enum QuestionStatus {
  Pending = "Pending",
  Uploading = "Uploading",
  Uploaded = "Uploaded",
}
