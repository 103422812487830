import { callApiPost, callPostWithProgress, logError } from "../utils/api";

var runningTasks = new Array<UploadProgress>();

function uploadAfterRecord(id: string, questionId: string, logId: number, videoData: Blob) {
  runStuckUpload();
  const allIsUploaded = getAllIsUploaded();

  var uploadProgress = new UploadProgress(id, questionId, logId, videoData);
  runningTasks.push(uploadProgress);

  if (allIsUploaded) {
    uploadProgress.run();
  }
}

export class UploadProgress {
  public id: string;
  public questionId: string;
  public logId: number;
  public videoData: Blob;
  public percent: number = 0;
  public thereWasAnError: boolean = false;
  public started: boolean = false;
  public finish: boolean = false;

  constructor(id: string, questionId: string, logId: number, videoData: Blob) {
    this.id = id;
    this.questionId = questionId;
    this.logId = logId;
    this.videoData = videoData;
  }

  public run() {
    this.started = true;
    setTimeout(() => this.uploadVideo(), 0);
  }

  async uploadVideo() {
    var urlStartData = null;
    while (true) {
      const resUrlStart = await callApiPost(
        `/${this.id}/question/${this.questionId}/UrlStart?ext=${getExtFromMimeType(this.videoData.type)}&logId=${this.logId}`
      );

      if (resUrlStart) {
        if (resUrlStart.ok) {
          urlStartData = await resUrlStart.json();
          if (urlStartData.fileName) {
            if (urlStartData.uploadUrl) {
              break;
            }
          }
        }
      }

      await logError(this.id, this.logId, `StartUpload${this.questionId}`);
      this.thereWasAnError = true;
    }

    this.thereWasAnError = false;
    while (true) {
      this.percent = 0;

      const resUpload = await callPostWithProgress(
        urlStartData.uploadUrl,
        this.videoData,
        this.handleUploadProgress
      );

      if (resUpload) {
        break;
      }

      await logError(this.id, this.logId, `BucketUpload${this.questionId}`);
      this.thereWasAnError = true;
    }

    this.thereWasAnError = false;
    while (true) {
      const resUrlDone = await callApiPost(
        `/${this.id}/question/${this.questionId}/UrlDone?fileName=${urlStartData.fileName}&logId=${this.logId}`
      );

      if (resUrlDone) {
        if (resUrlDone.ok) {
          break;
        }
      }

      await logError(this.id, this.logId, `EndUpload${this.questionId}`);
      this.thereWasAnError = true;
    }

    this.videoData = new Blob();
    this.thereWasAnError = false;
    this.percent = 100;
    this.finish = true;

    const nextUpload = runningTasks.find(x => !x.started);
    if (nextUpload) {
      nextUpload.run();
    }
  }

  handleUploadProgress = (p: any) => {
    this.percent = Math.floor((p.loaded / p.total) * 100);
  };
}

function runStuckUpload() {
  const allIsUploaded = getAllIsUploaded();
  if (allIsUploaded) {
    return;
  }

  const anyRunning = runningTasks.find(x => x.started && !x.finish);
  if (anyRunning) {
    return;
  }

  const nextUpload = runningTasks.find(x => !x.started);
  if (nextUpload) {
    nextUpload.run();
  }
}

function getAllIsUploaded(): boolean {
  for (var i = 0; i < runningTasks.length; i++) {
    if (!runningTasks[i].finish) return false;
  }

  return true;
}

function getPercent(questionId: string): number {
  const task = runningTasks.find((x) => x.questionId === questionId);
  if (task) {
    return task.percent;
  }

  return 100;
}

function getExtFromMimeType(mimeType: string) {
  if (mimeType.includes("mp4") || mimeType.includes("mpeg")) {
    return ".mp4";
  } else {
    return ".webm";
  }
}

const dataSendApi = {
  uploadAfterRecord,
  runStuckUpload,
  getAllIsUploaded,
  getPercent,
};

export default dataSendApi;
