import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const FinishErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page-content">
      <h1>{t("finishError.title")}</h1>
      <p>{t("finishError.text")}</p>
    </div>
  );
};

export default FinishErrorPage;
