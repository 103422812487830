import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import overviewApi from "../api/OverviewApi";
import SpinBox from "../components/common/SpinBox";
import { PAGE_FINISH_ERROR, PAGE_FINISH_SUCCESS } from "../consts/Pages";
import { logError } from "../utils/api";
import { setShowMessageWhenLeave } from "../utils/candidateLeave";

const UploadedPage: FC = () => {
  const navigate = useNavigate();

  const load = async () => {
    const data = overviewApi.getOverview();
    const loadRes = await overviewApi.loadOverview(data.id);

    if (loadRes === overviewApi.LoadResponse.AlreadyAnswered) {
      navigate(PAGE_FINISH_SUCCESS);
      return;
    }

    await logError(data.id, data.logId, "UploadedPage");

    navigate(PAGE_FINISH_ERROR);
  };

  useEffect(() => {
    (async () => {
      setShowMessageWhenLeave(false);
      await load();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SpinBox />;
};

export default UploadedPage;
