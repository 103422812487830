import React, { FC } from "react";
import SpinBox from "../../common/SpinBox";

const Loading: FC = () => {
  return (
    <div className="video-loading">
      <SpinBox />
    </div>
  );
};

export default Loading;
