import React, { Component } from "react";

interface IProps {
  onClickOutside: any;
  children: any;
}

export default class ClickOutside extends Component<IProps> {
  container = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('click', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
  }

  handleClick = (event: MouseEvent) => {
    const container = this.container.current;
    const { target } = event;
    const { onClickOutside } = this.props;

    if ((container && container === target) || (container && !container.contains(target as Node))) {
      onClickOutside();
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div ref={this.container}>
        {children}
      </div>
    );
  }
}