export default class VolumeAnalyzer {
  start(stream) {
    try {
      this.stats = [];
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
      this.audioSource = this.audioContext.createMediaStreamSource(stream);
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 512;
      this.analyser.minDecibels = -127;
      this.analyser.maxDecibels = 0;
      this.analyser.smoothingTimeConstant = 0.4;
      this.audioSource.connect(this.analyser);
      this.volumes = new Uint8Array(this.analyser.frequencyBinCount);
      
      this.volumeInterval = setInterval(this.volumeCallback, 100);
    } catch(e) {
      //Nothing.
    }
  }

  stop() {
    if (this.volumeInterval) {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
    }

    if (this.analyser) {
      try {
        this.analyser.close();
      } catch(e) {
        //Nothing.
      }
    }

    if (this.audioSource) {
      try {
        this.audioSource.close();
      } catch(e) {
        //Nothing.
      }
    }

    if (this.audioContext) {
      try {
        this.audioContext.close();
      } catch(e) {
        //Nothing.
      }
    }

    this.analyser = null;
    this.audioSource = null;
    this.audioContext = null;
  }

  volumeCallback = () => {
    try {
      this.analyser.getByteFrequencyData(this.volumes);
      let volumeSum = 0;
      for(const volume of this.volumes) {
        volumeSum += volume;
      }
      const averageVolume = volumeSum / this.volumes.length;
      // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
      this.stats.push((averageVolume * 100) / 127); //%
    } catch(e) {
      //Nothing.
    }
  }

  getState() {
    try {
      const firstValue = this.stats.length === 0 ? 0 : this.stats[0];
      var isDefined = false;
      var hasSameValue = true;
      var reached = false;

      for (var i = 0; i < this.stats.length; i++) {
        const val = this.stats[i];
        if (!val) {
          continue;
        }

        isDefined = true;

        if (val !== firstValue) {
          hasSameValue = false;
        }

        //20%
        if (val > 20) {
          reached = true;
        }
      }

      if (!isDefined) {
        return "isNotDefined";
      }

      if (hasSameValue) {
        return "hasSameValue";
      }

      if (!reached) {
        return "notReached";
      }

      return "ok";
    } catch(e) {
      //Nothing.
      return "error";
    }
  }
}