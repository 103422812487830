import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/common/Button";
import { PAGE_OVERVIEW } from "../consts/Pages";

const TermsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page-content">
      <h1>{t("terms.title")}</h1>
      <p className="text-lg">{t("terms.text1")}</p>
      <p>{t("terms.text2")}</p>
      <p>{t("terms.text3")}</p>
      <p>{t("terms.text4")}</p>
      <p>{t("terms.text5")}</p>
      <p>{t("terms.text6")}</p>
      <p>{t("terms.text7")}</p>
      <p>{t("terms.text8")}</p>
      <p>{t("terms.text9")}</p>
      <Button to={PAGE_OVERVIEW} captionRes="common.back" />
    </div>
  );
};

export default TermsPage;
