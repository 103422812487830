import * as bodyPix from "@tensorflow-models/body-pix";
import { ImageType } from "@tensorflow-models/body-pix/dist/types";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";

export var bodyPixInstance: bodyPix.BodyPix | null = null;

function load() {
  bodyPix
    .load({
      architecture: "MobileNetV1",
      outputStride: 16,
      multiplier: 0.50,
      quantBytes: 1,
    })
    .then((x) => (bodyPixInstance = x))
    .catch(() => {});
}

setTimeout(() => load(), 500);

export async function bodyPixDraw(
  inputVideoElement: ImageType,
  outputCanvas: HTMLCanvasElement
) {
  if (!bodyPixInstance) {
    return;
  }

  const segmentation = await bodyPixInstance.segmentPerson(inputVideoElement);

  const backgroundBlurAmount = 6;
  const edgeBlurAmount = 3;
  const flipHorizontal = false;

  bodyPix.drawBokehEffect(
    outputCanvas,
    inputVideoElement,
    segmentation,
    backgroundBlurAmount,
    edgeBlurAmount,
    flipHorizontal
  );
}
