import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../common/LanguageSwitcher";

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <LanguageSwitcher />
      <a href="mailto:support@lusk.io" className="button secondary">
        {t("footer.contactButton")}
      </a>
      <div className="copyright">
        <span>Copyright © 2022 Meta IT s.r.o.</span>
        <span>{t("footer.copyright")}</span>
      </div>
    </footer>
  );
};

export default Footer;
