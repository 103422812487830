var storedIsFlipped: boolean = true;
var storedBlurActivated: boolean = false;

function setIsFlipped(data: boolean) {
  storedIsFlipped = data;
}

function getIsFlipped(): boolean {
  return storedIsFlipped;
}

function setBlurActivated(data: boolean) {
  storedBlurActivated = data;
}

function getBlurActivated(): boolean {
  return storedBlurActivated;
}

const videoSettingsApi = {
  setIsFlipped,
  getIsFlipped,
  setBlurActivated,
  getBlurActivated,
};

export default videoSettingsApi;
