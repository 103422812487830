import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import overviewApi from "../../../api/OverviewApi";
import { logError } from "../../../utils/api";
import browser from "../../../utils/browser";
import { isSafariVersionOk } from "../../../utils/versions";

const Unsupported: FC = () => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();

  useEffect(() => {
    (async () => {
      await logError(data.id, data.logId, "Unsupported");
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let text = null;
  switch(browser.name) {
    case "safari":
      if (!isSafariVersionOk(browser.version)) {
        text = "unsupportedUpdateSafari";
      }
      break;
    case "ios":
      if (!isSafariVersionOk(browser.version)) {
        text = "unsupportedUpdateIos";
      }
  }

  if (text === null) {
    if (browser.os === "iOS") {
      text = "unsupportedUpdateOrAllowIos";
    }
  }

  if (text === null) {
    switch(browser.name) {
      case "safari": text = "unsupportedSafari"; break;
      case "ios": text = "unsupportedIos"; break;
      default: text = "unsupported";
    }
  }

  return (
    <div className="card">
      <div className="page-content">
        <div className="video-unsupported">
          {t("video." + text)}
          <br />
          <br />
          {browser.name} {browser.version} - {browser.os}
        </div>
      </div>
    </div>
  );
};

export default Unsupported;
