import React, { Component } from "react";
import PropTypes from "prop-types";
import { Decoder, tools, Reader } from "ts-ebml";
import CameraTestActions from "./components/CameraTestActions";
import Loading from "./components/Loading";
import Error from "./components/Error";
import Unsupported from "./components/Unsupported";
import UserButtons from "./components/UserButtons";
import CountDown from "./components/CountDown";
import Recording from "./components/Recording";
import Question from "./components/Question";
import videoSettingsApi from "../../api/VideoSettingsApi";
import { bodyPixInstance, bodyPixDraw } from "../../utils/bodyPixLoader";
import VolumeAnalyzer from "./volumeAnalyzer";
import { ImageCapture } from "image-capture"; //Safari
import { Buffer } from 'buffer';

//Fix error: ReferenceError: Buffer is not defined
//https://stackoverflow.com/a/68380045
Buffer.from('anything', 'base64');
window.Buffer = window.Buffer || require("buffer").Buffer;

/*
* Safari and Edge polyfill for createImageBitmap
* https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/createImageBitmap
*
* Support source image types Blob and ImageData.
*
* From: https://dev.to/nektro/createimagebitmap-polyfill-for-safari-and-edge-228
* Updated by Yoan Tournade <yoan@ytotech.com>
*/
if (!('createImageBitmap' in window)) {
	window.createImageBitmap = async function (data) {
		return new Promise((resolve,reject) => {
			let dataURL;
			if (data instanceof Blob) {
				dataURL = URL.createObjectURL(data);
			} else if (data instanceof ImageData) {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = data.width;
				canvas.height = data.height;
				ctx.putImageData(data,0,0);
        dataURL = canvas.toDataURL();
      } else if (data instanceof HTMLCanvasElement) {
        dataURL = data.toDataURL();
			} else {
				throw new Error('createImageBitmap does not handle the provided image source type');
			}
			const img = document.createElement('img');
			img.addEventListener('load',function () {
				resolve(this);
			});
			img.src = dataURL;
		});
	};
}

const MIME_TYPES = [
  'video/webm;codecs="vp8,opus"',
  "video/webm;codecs=h264",
  "video/webm;codecs=vp9",
  "video/webm",
];
const DEFAULT_MIMETYPE = "video/mp4"; //Safari
const CHUNKSIZE = 250;
const DATA_AVAILABLE_TIMEOUT = 2000;
const BITS_PER_SECOND = 524288;
const COUNT_DOWN_TIME = 3000;
const PROTECTION_TIME = 10000;

export default class VideoRecorder extends Component {
  static propTypes = {
    cameraTestMode: PropTypes.bool,
    questionsTestMode: PropTypes.bool,
    onReadQuestion: PropTypes.func,
    onRecordEnd: PropTypes.func,
    onSkipTest: PropTypes.func,
    onVolumeAnalyzerState: PropTypes.func,
  };

  state = {
    isFlipped: true,
    blurAllowed: false,
    blurActivated: false,
    isLoading: true,
    isBlurSwitching: false,
    streamIsReady: false,
    thereWasAnError: false,
    errorText: null,
    isRunningCountdown: false,
    isRecording: false,
    question: null,
    countDownEnd: 0,
    recordingEnd: 0,
  };

  mimeType =
    ((window.MediaRecorder && window.MediaRecorder.isTypeSupported)
      ? MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
      : undefined) || DEFAULT_MIMETYPE;
  isRecordingSupported =
    !!window.MediaRecorder && !!navigator.mediaDevices;
  destinationStream = new MediaStream();
  disableAllEvents = false;
  volumeAnalyzer = null;

  componentDidMount() {
    if (!this.isRecordingSupported) {
      return;
    }

    this.setState(
      {
        isFlipped: videoSettingsApi.getIsFlipped(),
        blurAllowed: bodyPixInstance !== null,
        blurActivated: videoSettingsApi.getBlurActivated(),
      },
      () => {
        this.turnOnCamera();
      }
    );
  }

  componentWillUnmount() {
    this.disableAllEvents = true;
    clearTimeout(this.protectionTimer);
    clearTimeout(this.timeoutCountDownTime);
    clearTimeout(this.timeLimitTimeout);
    clearTimeout(this.timeDataAvailableTimeout);
    this.turnOffCamera();
    this.FreeOldMediaRecorder();
  }

  turnOnCamera = () => {
    this.setState({
      isLoading: true,
      isBlurSwitching: false,
      streamIsReady: false,
      thereWasAnError: false,
      isRunningCountdown: false,
      isRecording: false,
    });

    if (this.props.cameraTestMode) {
      if (this.volumeAnalyzer) {
        this.volumeAnalyzer.stop();
      }

      this.volumeAnalyzer = new VolumeAnalyzer();
    };

    const constraints = {
      audio: true,
      //video: true,
      //Select front camera, if one is available.
      video: { facingMode: "user" },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.handleSuccess)
      .catch((e) => this.handleError("Get user media: " + e.message));
  };

  turnOffCamera = () => {
    if (this.volumeAnalyzer) {
      this.volumeAnalyzer.stop();
      this.volumeAnalyzer = null;
    }

    this.clearDestinationStream();
    this.sourceStream && this.sourceStream.getTracks().forEach((x) => x.stop());
  };

  handleSuccess = (sourceStream) => {
    this.sourceStream = sourceStream;

    this.initStreams();

    if (window.URL) {
      this.cameraVideo.srcObject = this.sourceStream;
    } else {
      this.cameraVideo.src = this.sourceStream;
    }

    if (this.props.cameraTestMode) {
      this.setState({
        isLoading: false,
        streamIsReady: true,
      });

      return;
    }

    this.handleLoadQuestion();
  };

  clearDestinationStream() {
    this.canvasStream = null;
    this.imageCapture = null;
    this.stopAnimationFrame();
    this.destinationStream.getTracks().forEach((x) => x.stop());
    this.destinationStream
      .getTracks()
      .forEach((x) => this.destinationStream.removeTrack(x));
  }

  initStreams() {
    this.clearDestinationStream();
    this.sourceStream
      .getAudioTracks()
      .forEach((x) => this.destinationStream.addTrack(x));
    this.changeStreams(this.state.blurActivated);
  }

  changeStreams(blurActivated) {
    if (blurActivated) {
      this.changeStreamBlur();
    } else {
      this.changeStreamNormal();
    }

    this.setState({
      isBlurSwitching: false,
    });

    //Reset timer.
    if (this.state.isRunningCountdown) {
      this.handleStartRecording();
    }
  }

  changeStreamNormal() {
    this.canvasStream = null;
    this.stopAnimationFrame();
    this.destinationStream.getVideoTracks().forEach((x) => x.stop());
    this.destinationStream
      .getVideoTracks()
      .forEach((x) => this.destinationStream.removeTrack(x));
    this.sourceStream
      .getVideoTracks()
      .forEach((x) => this.destinationStream.addTrack(x));
  }

  changeStreamBlur() {
    this.destinationStream
      .getVideoTracks()
      .forEach((x) => this.destinationStream.removeTrack(x));

    if (!this.imageCapture) {
      this.imageCapture = new ImageCapture(
        this.sourceStream.getVideoTracks()[0]
      );
    }

    this.canvasStream = this.outputCanvas.captureStream();
    this.canvasStream
      .getVideoTracks()
      .forEach((x) => this.destinationStream.addTrack(x));

    this.getBlurFrame();
  }

  getBlurFrame = () => {
    if (!this.imageCapture || !this.canvasStream) {
      return;
    }

    try {
      this.imageCapture
        .grabFrame()
        .then((imageBitmap) => {
          this.hiddenCanvas.width = imageBitmap.width;
          this.hiddenCanvas.height = imageBitmap.height;
          this.hiddenCanvas.getContext("2d").drawImage(imageBitmap, 0, 0);

          bodyPixDraw(this.hiddenCanvas, this.outputCanvas)
            .then(() => {
              if (this.imageCapture && this.canvasStream) {
                this.currentAF = window.requestAnimationFrame(
                  this.getBlurFrame
                );
              }
            })
            .catch(() => this.getBlurFrameError());
        })
        .catch(() => this.getBlurFrameError());
    } catch {
      this.getBlurFrameError();
    }
  };

  stopAnimationFrame() {
    if (!this.currentAF) {
      return;
    }

    window.cancelAnimationFrame(this.currentAF);
    this.currentAF = null;
  }

  getBlurFrameError() {
    //Ignore error while switching.
  }

  handleRetryAfterError = () => {
    this.turnOffCamera();
    this.turnOnCamera();
  }

  handleLoadQuestion = () => {
    this.props.onReadQuestion().then((question) => {
      if (!question) {
        this.handleError("Cannot load question.");
        return;
      }

      this.setState(
        {
          isLoading: false,
          streamIsReady: true,
          question: question,
        },
        () => {
          this.handleStartRecording();
        }
      );
    });
  };

  handleError = (errorText) => {
    if (this.disableAllEvents) {
      return;
    }

    clearTimeout(this.protectionTimer);
    clearTimeout(this.timeLimitTimeout);

    this.setState(
      {
        isLoading: false,
        isBlurSwitching: false,
        thereWasAnError: true,
        errorText: errorText,
      },
      () => {
        try {
          this.handleStopRecording();
        } catch {}

        this.turnOffCamera();
      }
    );
  };

  mediaRecorderHandleError = (stream) => {
    let errorText = "MediaRecorder ";

    try {
      errorText = errorText + stream.error.name;
    } catch {}

    this.handleError(errorText);
  }

  handleDataIssue = () => {
    this.handleError("Data issue handled.");
    return false;
  };

  isDataHealthOK = (event) => {
    if (this.disableAllEvents) {
      return false;
    }

    if (!event.data) {
      return this.handleDataIssue();
    }

    const dataCheckInterval = 2000 / CHUNKSIZE;

    // in some browsers (FF/S), data only shows up
    // after a certain amount of time ~everyt 2 seconds
    const blobCount = this.recordedBlobs.length;
    if (blobCount > dataCheckInterval && blobCount % dataCheckInterval === 0) {
      const blob = new window.Blob(this.recordedBlobs, {
        type: this.mimeType,
      });

      if (blob.size <= 0) {
        return this.handleDataIssue();
      }
    }

    return true;
  };

  handleDataAvailable = (event) => {
    if (this.isDataHealthOK(event)) {
      this.recordedBlobs.push(event.data);
    }
  };

  handleStopRecording = () => {
    this.mediaRecorder.stop();
  };

  handleStartRecording = () => {
    this.setState({
      countDownEnd: Date.now() + COUNT_DOWN_TIME,
      isRunningCountdown: true,
    });

    this.timeoutCountDownTime = setTimeout(
      () => this.startRecording(),
      COUNT_DOWN_TIME
    );
  };

  FreeOldMediaRecorder() {
    if (!this.mediaRecorder) {
      return;
    }

    this.mediaRecorder.onstop = null;
    this.mediaRecorder.onerror = null;
    this.mediaRecorder.ondataavailable = null;
    this.mediaRecorder = null;
  }

  startRecording = () => {
    this.captureThumb().then((thumbnail) => {
      this.thumbnail = thumbnail;

      this.recordedBlobs = [];

      const options = {
        mimeType: this.mimeType,
        bitsPerSecond: BITS_PER_SECOND,
      };

      this.FreeOldMediaRecorder();
      try {
        this.mediaRecorder = new window.MediaRecorder(
          this.destinationStream,
          options
        );
        this.mediaRecorder.onstop = this.handleStop;
        this.mediaRecorder.onerror = this.mediaRecorderHandleError;
        this.mediaRecorder.ondataavailable = this.handleDataAvailable;

        if (this.volumeAnalyzer) {
          this.volumeAnalyzer.start(this.sourceStream);
        }

        // collect 10ms of data
        this.mediaRecorder.start(CHUNKSIZE);

        this.setState({
          recordingEnd: Date.now() + this.state.question.duration,
          isRunningCountdown: false,
          isNextQuestionDisabled: true,
          isRecording: true,
        });

        this.protectionTimer = setTimeout(() => {
          this.handleProtectionTimer();
        }, PROTECTION_TIME);

        this.timeLimitTimeout = setTimeout(() => {
          this.handleStopRecording();
        }, this.state.question.duration);

        // mediaRecorder.ondataavailable should be called every 10ms,
        // as that's what we're passing to mediaRecorder.start() above
        this.timeDataAvailableTimeout = setTimeout(() => {
          if (this.recordedBlobs.length === 0) {
            this.handleError("Data available timeout.");
          }
        }, DATA_AVAILABLE_TIMEOUT);
      } catch(e) {
        this.handleError("Start recording: " + e.message);
      }
    });
  };

  handleStop = (event) => {
    if (this.disableAllEvents) {
      return;
    }

    clearTimeout(this.protectionTimer);
    clearTimeout(this.timeLimitTimeout);

    if (this.volumeAnalyzer) {
      this.volumeAnalyzer.stop();
    }

    this.FreeOldMediaRecorder();

    if (this.state.thereWasAnError) {
      return;
    }

    if (!this.recordedBlobs || this.recordedBlobs.length <= 0) {
      this.handleError("Handled stop without data.");
      return;
    }

    const videoBlob =
      this.recordedBlobs.length === 1
        ? this.recordedBlobs[0]
        : new window.Blob(this.recordedBlobs, { type: this.mimeType });

    const hasNextQuestion = this.state.question.hasNextQuestion;

    this.fixVideoMetadata(videoBlob).then((fixedVideoBlob) => {
      this.setState({
        isLoading: true,
      });

      if (this.volumeAnalyzer) {
        const state = this.volumeAnalyzer.getState();
        this.props.onVolumeAnalyzerState(state);
      }

      this.turnOffCamera();

      this.props.onRecordEnd(fixedVideoBlob, this.thumbnail).then((isOk) => {
        if (!isOk) {
          this.handleError("Cannot update question status.");
          return;
        }

        if (hasNextQuestion) {
          this.turnOnCamera();
        }
      });
    });
  };

  captureThumb = () =>
    new Promise((resolve, reject) => {
      var canvas = null;

      if (this.canvasStream) {
        canvas = this.outputCanvas;
      } else {
        canvas = document.createElement("canvas");
        canvas.width = this.cameraVideo.videoWidth;
        canvas.height = this.cameraVideo.videoHeight;
        canvas.getContext("2d").drawImage(
          this.cameraVideo,
          0, // top
          0, // left
          this.cameraVideo.videoWidth,
          this.cameraVideo.videoHeight
        );
      }

      canvas.toBlob((thumbnail) => {
        resolve(thumbnail);
      }, "image/jpeg");
    });

  // see https://bugs.chromium.org/p/chromium/issues/detail?id=642012
  fixVideoMetadata = (rawVideoBlob) => {

    //Safari
    if (this.mimeType.includes("mp4") || this.mimeType.includes("mpeg")) {
      return Promise.resolve(rawVideoBlob);
    }

    // see https://stackoverflow.com/a/63568311
    // moved to index.html
    /*Blob.prototype.arrayBuffer ??= function () {
      return new Response(this).arrayBuffer();
    }*/

    return rawVideoBlob.arrayBuffer().then((buffer) => {
      const decoder = new Decoder();
      const elements = decoder.decode(buffer);

      const reader = new Reader();
      reader.logging = false;
      reader.drop_default_duration = false;

      //https://stackoverflow.com/questions/66480784/not-able-to-create-seekable-video-blobs-from-mediarecorder-using-ebml-js-media/68639484#68639484
      elements.forEach((element) => {
        if (element.type !== "unknown") {
          reader.read(element)
        }
      });
      reader.stop();

      const seekableMetadata = tools.makeMetadataSeekable(
        reader.metadatas,
        reader.duration,
        reader.cues
      );

      const blobBody = buffer.slice(reader.metadataSize);

      const result = new Blob([seekableMetadata, blobBody], {
        type: rawVideoBlob.type,
      });

      return result;
    });
  };

  handleUserButtonsChange = (values) => {
    const isFlippedChanged = this.state.isFlipped !== values.isFlipped;
    const blurActivatedChanged =
      this.state.blurActivated !== values.blurActivated;

    if (isFlippedChanged) {
      this.setState({
        isFlipped: values.isFlipped,
      });
      videoSettingsApi.setIsFlipped(values.isFlipped);
    }

    if (blurActivatedChanged) {
      clearTimeout(this.timeoutCountDownTime);

      this.setState({
        blurActivated: values.blurActivated,
        isBlurSwitching: true,
      });

      videoSettingsApi.setBlurActivated(values.blurActivated);
      this.changeStreams(values.blurActivated);
    }
  };

  handleSkipTest = () => {
    this.props.onSkipTest();
  };

  handleProtectionTimer = () => {
    this.setState({
      isNextQuestionDisabled: false,
    });
  };

  renderCameraView() {
    const { isFlipped, blurActivated } = this.state;

    if (!this.isRecordingSupported) {
      return <Unsupported />;
    }

    return (
      <>
        <video
          key="videoRecorderVideo"
          className={isFlipped ? "video-flipped" : undefined}
          style={{ display: blurActivated ? "none" : undefined }}
          ref={(x) => (this.cameraVideo = x)}
          loop={false}
          muted={true}
          playsInline={true}
          autoPlay={true}
          controls={false}
        />
        <canvas
          key="videoRecorderHiddenCanvas"
          style={{ display: "none" }}
          ref={(x) => (this.hiddenCanvas = x)}
        />
        <canvas
          key="videoRecorderOutputCanvas"
          className={isFlipped ? "video-flipped" : undefined}
          style={{ display: blurActivated ? undefined : "none" }}
          ref={(x) => (this.outputCanvas = x)}
        />
      </>
    );
  }

  renderAction() {
    const { cameraTestMode } = this.props;

    const {
      isLoading,
      isBlurSwitching,
      thereWasAnError,
      errorText,
      isRunningCountdown,
      isRecording,
      countDownEnd,
      recordingEnd,
      blurActivated,
    } = this.state;

    if (!this.isRecordingSupported) {
      return null;
    }

    if (thereWasAnError) {
      return <Error errorText={errorText} mimeType={this.mimeType} blurActivated={blurActivated} onClick={this.handleRetryAfterError} />;
    }

    if (isLoading || isBlurSwitching) {
      return <Loading />;
    }

    if (isRunningCountdown) {
      return (
        <>
          <CountDown endTime={countDownEnd} />
          {this.renderUserButtons()}
        </>
      );
    }

    if (isRecording) {
      return (
        <>
          <Recording endTime={recordingEnd} />
          {this.renderUserButtons()}
        </>
      );
    }

    if (cameraTestMode) {
      return <>{this.renderUserButtons()}</>;
    }

    return null;
  }

  renderUserButtons() {
    const { isRecording, isFlipped, blurAllowed, blurActivated } = this.state;

    return (
      <UserButtons
        isRecording={isRecording}
        isFlipped={isFlipped}
        blurAllowed={blurAllowed}
        blurActivated={blurActivated}
        onChange={this.handleUserButtonsChange}
      />
    );
  }

  renderQuestions() {
    const { cameraTestMode, questionsTestMode } = this.props;

    const {
      question,
      isRecording,
      isRunningCountdown,
      thereWasAnError,
      isLoading,
      isBlurSwitching,
      isNextQuestionDisabled,
    } = this.state;

    if (
      cameraTestMode ||
      !this.isRecordingSupported ||
      thereWasAnError ||
      isLoading ||
      isBlurSwitching ||
      !(isRecording || isRunningCountdown)
    )
      return null;

    return (
      <Question
        isRecording={isRecording}
        cameraTestMode={cameraTestMode}
        questionsTestMode={questionsTestMode}
        isNextQuestionDisabled={isNextQuestionDisabled}
        text1={question.text1}
        text2={question.text2}
        hasNextQuestion={question.hasNextQuestion}
        onNextQuestionClick={this.handleStopRecording}
        onSkipTestClick={this.handleSkipTest}
      />
    );
  }

  render() {
    return (
      <>
        <div className="video-container" key="videoRecorderContainer">
          {this.renderCameraView()}
          {this.renderAction()}
        </div>
        {this.props.cameraTestMode && this.isRecordingSupported && (
          <CameraTestActions
            runDisabled={
              this.state.isRunningCountdown ||
              this.state.isRecording ||
              this.state.isLoading ||
              this.state.isBlurSwitching ||
              !this.state.streamIsReady ||
              this.state.thereWasAnError
            }
            onClick={this.handleLoadQuestion}
          />
        )}
        {this.renderQuestions()}
      </>
    );
  }
}
