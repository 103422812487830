import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import overviewApi from "../api/OverviewApi";
import Button from "../components/common/Button";
import { PAGE_OVERVIEW } from "../consts/Pages";

//todo: aplikovat css soubor
const WelcomePage: FC = () => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();

  return (
    <>
      <div className="card">
        <div className="page-content">
          <h1>
            {t("welcome.title")}, <span className="primary">{data.name}</span>
          </h1>
          <p>{t("welcome.text1")}</p>
          <p>{t("welcome.text2")}</p>
          <p>{t("welcome.text3", { companyName: data.companyName })}</p>
        </div>
      </div>
      <div className="page-actions__center">
        <Button lg to={PAGE_OVERVIEW} captionRes="common.continue" />
      </div>
    </>
  );
};

export default WelcomePage;
