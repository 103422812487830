import { Question } from "../models/Question";
import { callApiGet, callApiPut, logError } from "../utils/api";

async function getQuestion(
  id: string,
  questionId: string,
  logId: number,
): Promise<Question | null> {
  const response = await callApiGet(
      `/${id}/question/${questionId}?logId=${logId}`
  );
  if (!response || !response.ok) {
    await logError(id, logId, `GetQuestion${questionId}`);
    return null;
  }

  var data = await response.json();
  return data;
}

async function setUploadingState(
  id: string,
  questionId: string,
  logId: number,
  mimeType: string,
  blurActivated: boolean,
  thumbnail: Blob,
): Promise<boolean> {
  const data = new FormData();
  data.append('file', thumbnail);

  const response = await callApiPut(`/${id}/question/${questionId}?logId=${logId}&mimeType=${mimeType}&blurActivated=${blurActivated}`, data);

  if (!response) {
    await logError(id, logId, `SetUploadingState${questionId}`);
    return false;
  }

  return response.ok;
}

const questionsApi = {
  getQuestion,
  setUploadingState,
};

export default questionsApi;
