import { gte } from "semver";

export function isVersionOk(actualVersion: string, minimalVersion: string): boolean {
  try {
    return gte(actualVersion, minimalVersion);
  } catch {
    return false;
  }
}

export function isSafariVersionOk(actualVersion: string): boolean {
  return isVersionOk(actualVersion, "14.0.2");
}