import axios from 'axios'; 
import config from '../config'

const HEADERS: any = {
  Accept: 'application/json;charset=utf-8',
  'Access-Control-Allow-Origin': '*',
};

const HEADERS_JSON: any = {
  'Content-Type': 'application/json;charset=utf-8',
}

const HEADERS_DATA: any = {
  'Content-Type': 'application/x-www-form-urlencoded',
}

const HEADERS_TEXT: any = {
  'Content-Type': 'text/plain;charset=utf-8',
}

export const callApiGet = async (
    path: string,
    data?: any,
    abortController?: AbortController,
  ) =>
    callApiBase(
      path,
      'GET',
      data,
      abortController,
    );

export const callApiPost = async (
  path: string,
  data?: any,
  abortController?: AbortController,
) =>
  callApiBase(
    path,
    'POST',
    data,
    abortController,
  );

  export const callApiPut = async (
    path: string,
    data?: any,
    abortController?: AbortController,
  ) =>
    callApiBase(
      path,
      'PUT',
      data,
      abortController,
    );

async function callApiBase(path: string, method: string, data?: any, abortController?: AbortController) {
  const content = (data as FormData) ? HEADERS_DATA : HEADERS_JSON;
  const body = (data as FormData) ? data : JSON.stringify(data);

  let response: Response;
  try {
    response = await fetch(config.apibase + path, {
      method,
      headers: { ...HEADERS, content },
      body: body,
      signal: abortController ? abortController.signal : null,
    });
  } catch (error) {
    return null;
  }

  return response;
}

export async function callPostWithProgress(path: string, data: any, handleUploadProgress: any) {
  try
  {
    const res = await axios.request({
      method: "POST",
      url: path,
      data: data,
      headers: { ...HEADERS, HEADERS_DATA },
      onUploadProgress: (p) => handleUploadProgress(p)
    });

    return res.status === 200;
  }
  catch {
    return false;
  }
}

export async function logError(id: string, logId: number, error: string) {
  try {
    await fetch(`${config.apibase}/${id}/LogError/${logId}`, {
      method: "POST",
      headers: { ...HEADERS, HEADERS_TEXT },
      body: error,
    });
  } catch (error) {}
}

export async function logAnalyzer(id: string, logId: number, state: string) {
  try {
    return callApiPost(`/${id}/Log/${logId}/Analyzer/${state}`);
  } catch (error) {}
}