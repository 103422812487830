import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface UserButtonsChanged {
  isFlipped: boolean;
  blurActivated: boolean;
}

const UserButtons: FC<{
  isRecording: boolean;
  isFlipped: boolean;
  blurAllowed: boolean;
  blurActivated: boolean;
  onChange(values: UserButtonsChanged): void;
}> = ({ isRecording, isFlipped, blurAllowed, blurActivated, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="video-controls">
      <label className={`switch ${isFlipped ? "active" : ""}`}>
        <input
          type="checkbox"
          checked={isFlipped}
          onChange={() => onChange({ isFlipped: !isFlipped, blurActivated })}
        />
        <div />
        <span className="label">{t("video.flip")}</span>
      </label>
      {blurAllowed && (
        <>
          <br />
          <label className={`switch ${blurActivated ? "active" : ""}`}>
            <input
              type="checkbox"
              checked={blurActivated}
              onChange={() =>
                onChange({ isFlipped, blurActivated: !blurActivated })
              }
              disabled={isRecording}
            />
            <div />
            <span className="label">{t("video.blur")}</span>
          </label>
        </>
      )}
    </div>
  );
};

export default UserButtons;
