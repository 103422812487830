import React, { FC } from "react";

const Progress: FC<{
  caption: string;
  percent: number;
}> = ({ caption, percent }) => {
  return (
    <>
      <p className="progress-title">{caption}</p>
      <div className="progress-container">
        <div className="progress-filer" style={{ width: percent + "%" }}>
          <span className="progress-label">{`${percent} %`}</span>
        </div>
      </div>
    </>
  );
};

export default Progress;
