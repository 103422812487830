import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dataSendApi from "../api/DataSendApi";
import overviewApi from "../api/OverviewApi";
import questionsApi from "../api/QuestionsApi";
import videoSettingsApi from "../api/VideoSettingsApi";
import VideoRecorder from "../components/video/videoRecorder";
import { PAGE_UPLOADING } from "../consts/Pages";
import { QuestionStatus } from "../models/Question";
import { VideoRecorderQuestion } from "../models/VideoRecorderQuestion";
import { setShowMessageWhenLeave } from "../utils/candidateLeave";

const QuestionsPage: FC = () => {
  const { t } = useTranslation();
  const data = overviewApi.getOverview();
  const navigate = useNavigate();
  var questionIndex = 1;
  const questions = data.questions
    .map((item, index) => {
      item.showIndex = index + 1;
      return item;
    })
    .filter((x) => x.status !== QuestionStatus.Uploaded);

  useEffect(() => {
    setShowMessageWhenLeave(true);
  }, []);

  const hasNewQuestion = (): boolean => {
    return questionIndex < questions.length;
  };

  const getQuestionId = (): string => {
    return questions[questionIndex - 1].id;
  };

  const getQuestionShowIndex = (): number => {
    return questions[questionIndex - 1].showIndex;
  };

  const handleTestQuestion = async (): Promise<VideoRecorderQuestion | null> => {
    const question = await questionsApi.getQuestion(data.id, getQuestionId(), data.logId);
    if (!question) {
      return null;
    }

    return {
      hasNextQuestion: hasNewQuestion(),
      duration: question.duration,
      text1: t("questions.title", { index: getQuestionShowIndex() }),
      text2: question.text,
    };
  };

  const handleRecordEnd = async (
    videoData: Blob,
    thumbnail: Blob
  ): Promise<boolean> => {
    const res = await questionsApi.setUploadingState(
      data.id,
      getQuestionId(),
      data.logId,
      videoData.type,
      videoSettingsApi.getBlurActivated(),
      thumbnail,
    );
    if (!res) {
      return false;
    }

    //Dont wait.
    dataSendApi.uploadAfterRecord(data.id, getQuestionId(), data.logId, videoData);

    if (!hasNewQuestion()) {
      navigate(PAGE_UPLOADING);
    }

    questionIndex++;
    return true;
  };

  return (
    <VideoRecorder
      cameraTestMode={false}
      questionsTestMode={false}
      onReadQuestion={handleTestQuestion}
      onRecordEnd={handleRecordEnd}
    />
  );
};

export default QuestionsPage;
